<template>
  <!-- 面试详情==》》个人信息页面 -->
  <div class="viewInfo">
    <van-nav-bar :title="$t('viewInfo.InfoTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="content">
      <!-- 基本信息 -->
      <div class="baseInfo">
        <!-- 面试人基本信息 -->
        <div class="infoCard">
          <div class="zp">
            <svg-icon style="width:100%;height:100%;"
                      icon-class="default_avator" />
          </div>
          <div class="up">
            <p>{{ baseInfo.empname }}</p>
            <p>
              <span>{{ baseInfo.workyear }}{{$t('viewInfo.InfoTxt2')}}</span>
              <span>{{ baseInfo.degree }}</span>
              <span>{{ baseInfo.age }}{{$t('viewInfo.InfoTxt3')}}</span>
            </p>
            <p>{{ baseInfo.reqjob }}</p>
          </div>
          <div class="down">
            <span>{{ baseInfo.jobstatus }}</span>
          </div>
        </div>
        <!-- 招聘人信息 -->
        <div class="recruitInfo">
          <p>{{$t('viewInfo.InfoTxt4')}}</p>
          <van-row>
            <van-col span="8">
              <span>{{$t('viewInfo.InfoTxt5')}}</span>
            </van-col>
            <van-col span="15"
                     offset="1">
              <span style="color: #2b8df0">{{ baseInfo.telephone }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8">
              <span>{{$t('viewInfo.InfoTxt6')}}</span>
            </van-col>
            <van-col span="15"
                     offset="1">
              <span style="color: #2b8df0">{{ baseInfo.email }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8">
              <span>{{$t('viewInfo.InfoTxt7')}}</span>
            </van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.homeaddress }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8">
              <span>{{$t('viewInfo.InfoTxt8')}}</span>
            </van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.degree }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8">
              <span>{{$t('viewInfo.InfoTxt9')}}</span>
            </van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.school }}</span>
            </van-col>
          </van-row>
          <van-row v-if="RsWorkHis.length > 0">
            <van-col span="8">
              <span>{{$t('viewInfo.InfoTxt10')}}</span>
            </van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ RsWorkHis[0].company }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8">
              <span>{{$t('viewInfo.InfoTxt11')}}</span>
            </van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ baseInfo.reqmoney }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- 个人优势 -->
      <van-collapse v-model="activeNames">
        <van-collapse-item :title="$t('viewInfo.InfoTxt26')"
                           name="5">
          <div v-if="RsIV.length>0">
            <div class="ivRecord"
                 v-for="(it,index) in RsIV"
                 :key="index">
              <div class="record_child">
                <van-row>
                  <van-col span="8">
                    <span>{{$t('viewInfo.InfoTxt27')}}</span>
                  </van-col>
                  <van-col span="15"
                           offset="1">
                    <span>{{ it.iv_times }}</span>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">
                    <span>{{$t('viewInfo.InfoTxt28')}}</span>
                  </van-col>
                  <van-col span="15"
                           offset="1">
                    <span>{{ it.iv_type }}</span>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">
                    <span>{{$t('viewInfo.InfoTxt29')}}</span>
                  </van-col>
                  <van-col span="15"
                           offset="1">
                    <span>{{ it.iv_date }}</span>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">
                    <span>{{$t('viewInfo.InfoTxt30')}}</span>
                  </van-col>
                  <van-col span="15"
                           offset="1">
                    <span>{{ it.iv_pj }}</span>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">
                    <span>{{$t('viewInfo.InfoTxt31')}}</span>
                  </van-col>
                  <van-col span="15"
                           offset="1">
                    <span>{{ it.iv_res }}</span>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="8">
                    <span>{{$t('viewInfo.InfoTxt32')}}</span>
                  </van-col>
                  <van-col span="15"
                           offset="1">
                    <span>{{ it.empname }}</span>
                  </van-col>
                </van-row>
                <div class="show_pj">
                  <van-button type="primary"
                              size="mini"
                              @click="showEvaItems(it.autoid)">{{$t('viewInfo.InfoTxt33')}}</van-button>
                </div>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item :title="$t('viewInfo.InfoTxt12')"
                           name="1">
          <div class="education"
               v-for="(edItem, edIdx) in RsEduHis"
               :key="edIdx">
            <div class="ed_header">
              <div class="ed_hl">
                <span class="circle"></span>
                <span>{{ edItem.edubt }}</span> ——
                <span>{{ edItem.eduet }}</span>
              </div>
            </div>
            <div class="ed_hr"
                 style="margin-bottom: 0.24rem;"
                 v-if="edItem.edumajor">{{$t('viewInfo.InfoTxt13')}}{{ edItem.edumajor }}</div>
            <div class="ed_body"
                 style="margin-bottom:0;">
              <div class="ed_bl"
                   style="padding-left: 0.5rem;">
                <span v-show="edItem.edudegree">{{ edItem.edudegree }}</span>
                <span>{{ edItem.eduorg }}</span>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item :title="$t('viewInfo.InfoTxt14')"
                           name="2">
          <div class="workHistory"
               v-for="(workItem, wIdx) in RsWorkHis"
               :key="wIdx">
            <div class="workgs">
              <span class="circle"></span>
              <span>{{ workItem.company }}</span>
            </div>

            <div class="work_body">
              <div class="work_head">
                <div class="whl"
                     style="max-width: 48%;padding-right: 5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                  <span>{{ workItem.station }}</span>
                </div>
                <div class="whr">
                  <span style="font-size: 0.36rem;">{{ workItem.workbt }} - {{ workItem.worket ? workItem.worket : $t('viewInfo.InfoTxt15') }}</span>
                </div>
              </div>
              <span>{{$t('viewInfo.InfoTxt16')}}</span>
              <p style="text-align: justify;">{{ workItem.workdesc }}</p>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item :title="$t('viewInfo.InfoTxt17')"
                           name="3">
          <ul>
            <li style="text-align: justify;">{{ baseInfo.selfdesp }}</li>
          </ul>
        </van-collapse-item>
        <van-collapse-item :title="$t('viewInfo.InfoTxt18')"
                           name="4">
          <ul v-if="fileList.length>0"
              style="display: flex;flex-wrap: wrap;justify-content: space-between;">
            <li style="text-align: justify; padding: 0px 20px 10px 20px;"
                v-for="(item,index) in fileList"
                :key="index"
                @click="showFile(item)">
              <img v-if="item.type == 'doc'"
                   src="@/assets/img/word.png"
                   alt />
              <img v-else-if="item.type == 'xls'"
                   src="@/assets/img/excel2.png"
                   alt />
              <img v-else-if="item.type == 'ppt'"
                   src="@/assets/img/ppt2.png"
                   alt />
              <img v-else-if="item.type == 'pic'"
                   src="@/assets/img/picture.png"
                   alt />
              <img v-else-if="item.type == 'zip'"
                   src="@/assets/img/zip.png"
                   alt />
              <img v-else-if="item.type == 'default' || 'pdf'"
                   src="@/assets/img/xmind.png"
                   alt />
            </li>
          </ul>
          <ul v-else>
            <li style="text-align: justify;">{{$t('viewInfo.InfoTxt19')}}</li>
          </ul>
        </van-collapse-item>
      </van-collapse>
      <!-- 底部 -->

      <screenFooter v-show="footerType == '简历初筛'" />
      <arrange-footer @arrangeView="arrangeView"
                      @toElimination="toElimination"
                      v-show="footerType == '安排面试'" />
      <offer-footer @sendOffer="sendOffer"
                    v-show="footerType == '发送Offer'" />
    </div>
    <div class="viewFooter"
         v-show="footerType == '待入职'">
      <span @click="signView"
            :class="!baseInfo.stationid?'limitclick':''">{{$t('viewInfo.InfoTxt20')}}</span>
      <span @click="zitoElimination">{{$t('viewInfo.InfoTxt21')}}</span>
    </div>

    <van-dialog v-model="show"
                title
                :showConfirmButton="false">
      <van-icon name="cross"
                @click="closeEwm" />
      <div v-if="loadingCPqr"
           class="view_loading">
        <van-loading type="spinner"
                     color="#1989fa" />
      </div>
      <div v-else>
        <div class="ewm">
          <qrcode :text="url" />
        </div>
        <p>
          {{$t('viewInfo.InfoTxt22')}}
          <br />
          {{$t('viewInfo.InfoTxt23')}}-
        </p>
        <van-button v-if="showCheckResBtn"
                    color="#2b8df0"
                    size="small"
                    style="margin-bottom:10px;"
                    @click="viewCPResults">查看测评结果</van-button>
      </div>
    </van-dialog>

    <!-- pdf内置播放器 -->
    <pdfView :pdf="pdf"></pdfView>

    <!-- 查看评价项弹窗 -->
    <van-popup class="pjPopup"
               v-model="showPjPop"
               position="bottom"
               closeable
               round
               :style="{ height: '80%' }">
      <div class="pup_title">{{$t('viewInfo.InfoTxt34')}}</div>
      <div class="pjmxl"
           v-if="pjdata.length > 0">
        <div class="pjmxl_top">
          <div class="pjmxl_top_left">
            <span>{{$t('viewEvaluate.EvaTxt3')}}</span>
          </div>
          <div class="pjmxl_top_right">
            <span>{{$t('viewEvaluate.EvaTxt4')}}</span>
          </div>
        </div>
        <div class="pjmxl_body">
          <div class="pjmxl_top_list"
               v-for="(value,key) in pjdata"
               :key="key">
            <!-- <div class="fzclick"
            v-if="!!qresult"></div>-->
            <div class="flexboxbox">
              <div class="pjmxl_top_listLeft">{{ value.optitle }}</div>
              <div v-if="value.opflagname == '单选' || value.opflagname == '多选'"
                   class="pjmxl_top_listRight">
                <label :for="'id_a_'+value.autoid+'_k_'+idx"
                       v-for="(itm,idx) in value.optarr"
                       :key="idx">
                  <input :disabled="true"
                         type="radio"
                         :id="'id_a_'+value.autoid+'_k_'+idx"
                         v-if="value.opflagname == '单选'"
                         v-model="value.select"
                         :value="itm" />
                  <input :disabled="true"
                         type="checkbox"
                         :id="'id_a_'+value.autoid+'_k_'+idx"
                         v-model="value.select"
                         :value="itm"
                         v-else-if="value.opflagname == '多选'" />
                  <span>{{ itm }}</span>
                </label>
              </div>
              <div v-else-if="value.opflagname == '文本'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datetimePicker"
                           :value="value.select" />
              </div>
              <div v-else-if="value.opflagname == '日期'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datePicker"
                           :value="value.select" />
              </div>
              <div v-else-if="value.opflagname == '日期时间'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datetimePicker"
                           :value="value.select" />
              </div>
              <div v-else-if="value.opflagname == '整数'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datetimePicker"
                           :value="value.select" />
              </div>
              <div v-else-if="value.opflagname == '浮点数'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datetimePicker"
                           :value="value.select" />
              </div>
            </div>
            <div v-if="value.opflagname == '单选'"
                 style="position: absolute;
                      right: -15px;
                      top: 0px;
                      color: red;
                      font-size: 16px;
                      height: 100%;
                      display: flex;
                      align-items: center;">{{value.score}}</div>
          </div>
        </div>
        <div style="text-align: left; font-size: 16px; color: red; margin-top: 10px;">评价总得分：{{totalScore}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { Notify } from 'vant'
import qrcode from '@components/qrcode'
import {
  getResumeDetail,
  getModuleAttFileList,
  checkFileExists,
  getDownLoadFileName,
  getPjDetail,
  checkIvQtRes
} from '@api/wxzp.js'
import screenFooter from './screenFooter'
import arrangeFooter from './arrangeFooter'
import offerFooter from './offerFooter'
import { Toast } from 'vant'
import pdfView from '@components/pdfView/index.vue'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  components: {
    screenFooter,
    arrangeFooter,
    offerFooter,
    qrcode,
    pdfView
  },
  data () {
    return {
      userInfo,
      footerType: '',
      show: false,
      baseInfo: {},
      RsWorkHis: [],
      activeNames: ['1', '2', '3', '4', '5'],
      RsEduHis: [],
      url: '',
      fileList: [], // 附件列表
      attachfilepath: 'AttachFile',
      pdf: '',
      RsIV: [], // 面试记录
      showPjPop: false, // 查看评价项弹窗
      pjdata: [], // 评价详情
      qresult: 'ssss', // 面试结果
      totalScore: 0,
      loadingCPqr: false,
      showCheckResBtn: false,
      viewIte: null
    }
  },

  methods: {
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm) +
            ':' +
            this.add0(s)
          )
        }
      } else
      {
        return ''
      }
    },
    // 查看面试评价详情
    getPjContent (autoid) {
      this.totalScore = 0
      getPjDetail({
        pjid: autoid
      }).then(res => {
        // console.log(res,'resresres');
        let pjarr = res.data
        for (let i = 0; i < pjarr.length; i++)
        {
          pjarr[i].optarr = pjarr[i].oplist.split(',')
          if (pjarr[i].opflagname != '多选')
          {
            if (pjarr[i].opres)
            {
              pjarr[i].select = pjarr[i].opres
            } else
            {
              pjarr[i].select = ''
            }
          } else
          {
            if (pjarr[i].opres)
            {
              pjarr[i].select = pjarr[i].opres.split('|')
            } else
            {
              pjarr[i].select = []
            }
          }
          if (pjarr[i].opflagname == '单选')
          {
            if (!pjarr[i].score)
            {
              pjarr[i].score = 0
            }
            this.totalScore += pjarr[i].score
          }
        }
        // console.log(pjarr);
        this.pjdata = pjarr
      })
    },
    // 面试记录--查看评价项
    showEvaItems (autoid) {
      this.showPjPop = true
      this.getPjContent(autoid)
    },
    // 面试签到
    signView () {
      if (this.baseInfo.stationid)
      {
        this.show = true
        this.loadingCPqr = true
        this.viewIte = this.baseInfo
        checkIvQtRes({
          rsid: this.baseInfo.autoid
        }).then(res => {
          if (res.data[0].info == 'Y')
          {
            this.showCheckResBtn = true
          } else
          {
            this.showCheckResBtn = false
          }
          this.url =
            location.origin +
            '/scanInterviewEntry?stationid=' +
            this.$route.query.stationid
          this.loadingCPqr = false
        })

        console.log(this.url)
      } else
      {
        Toast(this.$t('viewInfo.InfoTxt24'))
      }
    },
    // 查看测评结果
    viewCPResults () {
      this.$router.push({
        path: '/interviewResults',
        query: {
          rsid: this.viewIte.autoid,
          fromPath:
            '/viewInfo?autoid=' +
            this.$route.query.autoid +
            '&stationid=' +
            this.$route.query.stationid +
            '&footerType=待入职&pjid=' +
            this.$route.query.pjid +
            '&iv_res=' +
            this.$route.query.iv_res +
            '&iv_pj=' +
            this.$route.query.iv_pj +
            '&remark=' +
            this.$route.query.remark
        }
      })
    },
    onClickLeft () {
      if (this.footerType == '简历初筛')
      {
        this.$router.push('/resumeSel')
      } else if (this.footerType == '待入职')
      {
        this.$router.push('/toInterview')
      } else if (this.footerType == '安排面试')
      {
        this.$router.push('/arrangeInterview')
      } else if (this.footerType == '发送Offer')
      {
        this.$router.push('/sendOffer')
      }
    },
    zitoElimination () {
      this.$router.push({
        path: '/viewEvaluate',
        query: {
          autoid: this.$route.query.autoid,
          returnPath: 'viewInfo',
          iv_res: this.$route.query.iv_res,
          pjid: this.$route.query.pjid,
          iv_pj: this.$route.query.iv_pj,
          remark: this.$route.query.remark
        }
      })
    },
    onClickRight () { },
    closeEwm () {
      this.show = false
    },
    //跳转到面试淘汰页面
    toElimination () {
      this.$router.push({
        path: '/viewElimination',
        query: { title: '淘汰' }
      })
    },
    // 跳转到面试通知
    arrangeView () {
      this.$router.push('/interviewNotice')
    },
    // 跳转到Offer通知
    sendOffer () {
      this.$router.push('/offerNotice')
    },
    getResumeDetailData () {
      getResumeDetail({
        autoid: this.$route.query.autoid,
        username: this.userInfo.username
      }).then(res => {
        console.log(res, 'getResumeDetailData')
        // 基本信息
        this.baseInfo = res.ResumeInfo[0]
        // 工作经验
        this.RsWorkHis = res.RsWorkHis
        // 教育经验
        this.RsEduHis = res.RsEduHis
        // 面试记录
        this.RsIV = res.RsIV
      })
    },
    // 获取附件列表
    getModuleAttFileListData () {
      getModuleAttFileList({
        moduleno: 306,
        mautoid: this.$route.query.autoid,
        username: this.userInfo.username
      }).then(res => {
        this.fileList = this.filehandleData(res)
        // for (let index = 0; index < this.fileList.length; index++)
        // {
        //   const item = this.fileList[index]
        // }
      })
    },
    // 附件数据处理
    filehandleData (list) {
      let groups = [] // 分组[后缀]
      let res = [] // 第一次分类的集合
      if (list.module_atlist)
      {
        list.module_atlist.forEach(item => {
          let type = ''
          let groupName = !item.aftype ? '' : item.aftype
          const ext = item.fileext.slice(1) // 后缀
          let url = ''
          if ('doc|docx'.includes(ext))
          {
            name = 'WORD文件'
            type = 'doc'
            url = require('@assets/img/word.png')
          } else if ('xlsx|xls'.includes(ext))
          {
            name = 'EXCEL文件'
            type = 'xls'
            url = require('@assets/img/excel2.png')
          } else if ('ppt|pptx'.includes(ext))
          {
            name = 'PPT文件'
            type = 'ppt'
            url = require('@assets/img/ppt2.png')
          } else if ('pdf'.includes(ext))
          {
            name = 'PDF文件'
            type = 'pdf'
            url = require('@assets/img/picture.png')
          } else if ('png|jpg|jpeg|emp'.includes(ext))
          {
            name = '图片文件'
            type = 'pic'
            url = require('@assets/img/picture.png')
          } else if ('zip|rar'.includes(ext))
          {
            name = '压缩文件'
            type = 'zip'
            url = require('@assets/img/zip.png')
          } else
          {
            name = '其他文件'
            type = 'default'
            url = require('@assets/img/xmind.png')
          }
          item.type = type
          // item.url = url
          item.isImage = true
          res.push(item)
        })
      }
      if (list.other_atlist)
      {
        list.other_atlist.forEach(item => {
          let type = ''
          const ext = item.fileext.slice(1) // 后缀
          let groupName = !item.aftype ? '' : item.aftype
          let url = ''
          if ('doc|docx'.includes(ext))
          {
            name = 'WORD文件'
            type = 'doc'
            url = require('@assets/img/word.png')
          } else if ('xlsx|xls'.includes(ext))
          {
            name = 'EXCEL文件'
            type = 'xls'
            url = require('@assets/img/excel2.png')
          } else if ('ppt|pptx'.includes(ext))
          {
            name = 'PPT文件'
            type = 'ppt'
            url = require('@assets/img/ppt2.png')
          } else if ('pdf'.includes(ext))
          {
            name = 'PDF文件'
            type = 'pdf'
            url = require('@assets/img/picture.png')
          } else if ('png|jpg|jpeg|emp'.includes(ext))
          {
            name = '图片文件'
            type = 'pic'
            url = require('@assets/img/picture.png')
          } else if ('zip|rar'.includes(ext))
          {
            name = '压缩文件'
            type = 'zip'
            url = require('@assets/img/zip.png')
          } else
          {
            name = '其他文件'
            type = 'default'
            url = require('@assets/img/xmind.png')
          }
          item.type = type
          // item.url = url
          item.isImage = true
          res.push(item)
        })
      }
      return res
    },
    // 点击附件
    showFile (item) {
      if (item.type === 'pic' || 'pdf')
      {
        const params = {
          moduleno: item.moduleno,
          dflag: 2,
          autoid: parseInt(this.$route.query.autoid),
          filename: item.sfilename.split('.')[0], // filename不要带后缀
          fileext: item.fileext,
          downloadpath: this.attachfilepath
        }
        checkFileExists(params).then(re => {
          if (re == 'OK')
          {
            getDownLoadFileName(params).then(e => {
              // this.fileList[index].url = e
              if (item.type === 'pic')
              {
                ImagePreview([e])
              } else if (item.type === 'pdf')
              {
                this.pdf = e + '?time=' + new Date().getTime()
              } else
              {
                // window.open(e, '_blank');
                var u = navigator.userAgent
                var isAndroid =
                  u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
                if (isAndroid)
                {
                  //android终端
                  window.open(e)
                } else if (isiOS)
                {
                  //ios终端
                  window.location.href = e
                } else
                {
                  window.open(e)
                }
              }
            })
          } else
          {
            Notify({ type: 'warning', message: this.$t('viewInfo.InfoTxt25') })
          }
        })
      }
    }
  },
  created () {
    this.getResumeDetailData()
    this.getModuleAttFileListData()
  },
  mounted () {
    this.footerType = this.$route.query.footerType
  }
}
</script>

<style lang="less" scoped>
.viewInfo {
  position: relative;
  height: 100vh;
  // overflow: hidden;
  .pdfView {
    height: 0;
  }
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 232px);
    overflow: auto;
    box-sizing: border-box;
    .baseInfo {
      box-sizing: border-box;
      padding: 26px 30px;
      background: #fff;
      // margin-bottom: 24px;
      .infoCard {
        position: relative;
        width: 690px;
        height: 308px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
        opacity: 1;
        border-radius: 16px;
        padding-left: 36px;
        padding-right: 32px;
        margin-bottom: 42px;
        .zp {
          position: absolute;
          width: 132px;
          height: 132px;
          right: 32px;
          top: 52px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .up {
          border-bottom: 1px solid #ccc;
          padding: 42px 140px 26px 0px;
          box-sizing: border-box;
          p {
            margin: 0;
          }
          p:first-child {
            font-size: 52px;
            height: 52px;
            line-height: 52px;
            font-family: Source Han Sans CN;
            color: #000;
            margin-bottom: 16px;
          }
          p:nth-child(2) {
            font-size: 28px;
            line-height: 28px;
            font-family: Source Han Sans CN;
            color: #666;
            margin-bottom: 12px;
            span:first-child,
            span:nth-child(2) {
              border-right: 1px solid #999999;
            }
            span {
              padding: 0 14px;
            }
            span:first-child {
              padding-left: 0;
            }
          }
          p:nth-child(3) {
            line-height: 36px;
            font-size: 30px;
            font-family: Source Han Sans CN;
          }
        }
        .down {
          height: 84px;
          line-height: 84px;
          box-sizing: border-box;
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #666;
        }
      }
      .recruitInfo {
        p {
          font-size: 36px;
          font-family: Source Han Sans CN;
          color: #000;
        }
        .van-row {
          margin-bottom: 40px;
          .van-col:first-child {
            font-size: 32px;
            font-family: Source Han Sans CN;
            color: #999;
          }
          .van-col:last-child {
            font-size: 32px;
            font-family: Source Han Sans CN;
            color: #333;
          }
        }
      }
    }
    .van-collapse {
      /deep/.van-collapse-item {
        .van-cell {
          .van-cell__title {
            span {
              font-size: 36px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #000000;
              opacity: 1;
            }
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            .education {
              .ed_header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #666;
                font-size: 32px;
                font-family: Source Han Sans CN;
                margin-bottom: 18px;
                .ed_hl {
                  display: flex;
                  align-items: center;
                  .circle {
                    display: inline-block;
                    box-sizing: border-box;
                    width: 30px;
                    height: 30px;
                    background: #ffffff;
                    border: 6px solid #2b8df0;
                    border-radius: 50%;
                    margin-right: 16px;
                  }
                  span {
                  }
                }
                .ed_hr {
                }
              }
              .ed_body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;
                border-left: 1px solid #ccc;
                margin-bottom: 18px;
                margin-left: 14px;
                .ed_bl {
                  box-sizing: border-box;
                  font-family: Source Han Sans CN;
                  padding-left: 66px;
                  span:first-child {
                    display: inline-block;
                    width: 60px;
                    height: 36px;
                    text-align: center;
                    line-height: 36px;
                    border: 2px solid #2b8df0;
                    border-radius: 8px;
                    color: #2b8df0;
                    font-size: 24px;
                    margin-right: 16px;
                  }
                  span:last-child {
                    font-size: 32px;
                    color: #333333;
                  }
                }
                .ed_br {
                  font-size: 32px;
                  color: #333333;
                }
              }
            }
            .workHistory {
              .workgs {
                display: flex;
                align-items: center;

                .circle {
                  display: inline-block;
                  box-sizing: border-box;
                  width: 30px;
                  height: 30px;
                  background: #ffffff;
                  border: 6px solid #2b8df0;
                  border-radius: 50%;
                  margin-right: 16px;
                }
                span:last-child {
                  font-size: 32px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #000;
                }
              }
              .work_body {
                padding: 40px 0px;
                padding-left: 30px;
                border-left: 1px solid #ccc;
                margin-left: 14px;
                box-sizing: border-box;
                font-size: 32px;

                font-family: Source Han Sans CN;
                color: #333333;
                .work_head {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 30px;
                }

                span {
                }
                p {
                  margin: 0;
                }
              }
            }
            ul {
              li {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
              }
            }
            .ivRecord {
              box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
              opacity: 1;
              border-radius: 16px;
              margin-bottom: 20px;
              .record_child {
                padding: 20px;
                .van-row {
                  margin-bottom: 40px;
                  font-size: 32px;
                  .van-col--15 {
                    color: #333;
                  }
                }
                .show_pj {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  font-size: 14px;
                  .van-button {
                    font-size: 28px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
  .pjPopup {
    overflow: hidden;
    .pup_title {
      padding: 28px;
      text-align: center;
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
    .pjmxl {
      height: calc(100% - 96px);
      overflow: auto;
      width: 100%;
      background: #fff;
      // padding: 0.26667rem 0.42667rem;
      padding: 20px 50px 20px 40px;
      margin-bottom: 0.37333rem;
      .pjmxl_top {
        width: 100%;
        display: flex;
        height: 80px;
        align-items: center;
        border-top: 1px solid #666;
        border-left: 1px solid #666;
        border-right: 1px solid #666;
        border-bottom: 1px solid #666;
        .pjmxl_top_left {
          width: 30%;
          font-size: 28px;
          height: 100%;
          border-right: 1px solid #666;
          align-items: center;
          display: flex;
          justify-content: center;
          text-align: center;
          span {
            font-weight: bold;
          }
        }
        .pjmxl_top_right {
          width: 70%;
          height: 100%;
          font-size: 28px;
          align-items: center;
          display: flex;
          justify-content: center;
          text-align: center;
          span {
            font-weight: bold;
          }
        }
      }
      .pjmxl_body {
        width: 100%;
        .fzclick {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          z-index: 999999;
          top: 0;
        }
        .pjmxl_top_list {
          width: 100%;

          height: 80px;
          position: relative;
          align-items: center;
          border-left: 1px solid #666;
          border-right: 1px solid #666;
          border-bottom: 1px solid #666;
          input:disabled {
            background: blue;
          }
        }
        .flexboxbox {
          display: flex;
          width: 100%;
          height: 100%;
        }
        .pjmxl_top_listLeft {
          width: 30%;
          font-size: 24px;
          height: 100%;
          border-right: 1px solid #666;
          align-items: center;
          display: flex;
          justify-content: center;
          text-align: center;
        }
        .pjmxl_top_listRight {
          width: 70%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          label {
            font-size: 24px;
            display: flex;
            align-items: center;
          }
          .listRight_text {
            font-size: 24px;
            height: 95%;
            width: 98%;
          }
          .van-cell {
            margin-bottom: 0;
            padding: 0;
            font-size: 24px;
            height: 95%;
            width: 98%;
            border: 1px solid #666;
            /deep/.van-cell__value {
              .van-field__body {
                height: 100%;
                .van-field__control {
                  text-align: left;
                }
              }
            }
          }
          /deep/.van-stepper {
            .van-stepper__input {
              width: 60%;
            }
          }
        }
      }
    }
  }
}
.viewFooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  span {
    display: inline-block;
    width: 50%;
    height: 100%;
    line-height: 80px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2b8df0;
    text-align: center;
  }
  span:first-child {
    flex: 1;
    border-right: 1px solid #ccc;
  }
  span:nth-child(2) {
    flex: 1;
    // border-right:1px solid #ccc;
  }
  .van-dropdown-menu {
    flex: 1;
    /deep/ .van-ellipsis {
      font-size: 36px;
      color: #2b8df0;
    }
    /deep/.van-dropdown-menu__title--down::after {
    }
  }
}
.limitclick {
  color: #ccc !important;
}
</style>
